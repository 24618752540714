import P5 from "p5"
import { Graphic, GraphicType } from "./Graphic"
import { STATE } from "../constants"

export class Ground implements Graphic {
    private constructor() {}
    private static ground: Ground

    public static getInstance(): Ground {
        if (!Ground.ground) {
            Ground.ground = new Ground()
        }
        return Ground.ground
    }

    public x: number = 0
    public y: number = STATE.ground.y
    public width: number = STATE.canvas.width
    public height: number = 1
    readonly type = GraphicType.Ground

    public draw(p5: P5) {
        p5.stroke("black")
        p5.line(0, STATE.ground.y, STATE.canvas.width, STATE.ground.y)
    }
}
