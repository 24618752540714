import { Graphic, GraphicType } from "./Graphic"
import P5 from "p5"
import { Mob } from "../helpers/MobFactory"
import { images } from "../app"
import { STATE } from "../constants"

export class Bat implements Graphic, Mob {
    public width: number = 250
    public height: number = 250
    public x: number = STATE.canvas.width + this.width
    public y: number = STATE.ground.y + STATE.dino.squatHeight + 10
    readonly type = GraphicType.Mob

    constructor() {}

    public draw(p5: P5) {
        if (STATE.game.isRunning) {
            this.x += STATE.mobs.xSpeed - STATE.mobs.count * 0.25
        }

        p5.stroke("#dddddd")
        p5.rect(this.x, this.y, this.width, this.height)

        const img = images.bat
        p5.imageMode(p5.CORNER)
        p5.image(img, this.x, this.y, this.width, this.height)
    }
}
