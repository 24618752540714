import P5 from "p5"

import { Graphic, GraphicType } from "./Graphic"
import { STATE } from "../constants"

export class StartScreen implements Graphic {
    private static startScreen: StartScreen
    x = STATE.canvas.width / 2
    y = -(STATE.canvas.height / 2)
    width = 0
    height = 30
    readonly type = GraphicType.Env

    private constructor() {}

    public static getInstance(): StartScreen {
        if (!StartScreen.startScreen) {
            StartScreen.startScreen = new StartScreen()
        }
        return StartScreen.startScreen
    }

    draw(p5: P5) {
        if (!STATE.game.isRunning) {
            p5.scale(1, -1)
            p5.textSize(this.height)
            p5.rectMode(p5.CENTER)
            p5.textAlign(p5.CENTER, p5.CENTER)
            p5.text("Click to (Re)start", this.x, this.y)
            p5.rectMode(p5.CORNER)
        }
    }
}
