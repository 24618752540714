import P5 from "p5"
import "p5/lib/addons/p5.dom"
import { CompoundGraphic } from "./entities/CompoundGraphic"
import { Dino } from "./entities/Dino"
import { Ground } from "./entities/Ground"
import { STATE } from "./constants"
import { MobFactory } from "./helpers/MobFactory"
import { StartScreen } from "./entities/StartScreen"

let mobSpawnerTimeoutToken: NodeJS.Timeout | undefined

export const fixCoordinates = (p5: P5) => {
    p5.translate(0, STATE.canvas.height)
    p5.scale(1, -1)

    for (let element of document.getElementsByClassName(
        "p5Canvas"
    ) as unknown as Element[]) {
        element.addEventListener("contextmenu", (e: MouseEvent) =>
            e.preventDefault()
        )
    }
}

const mobSpawner = (composite: CompoundGraphic) => {
    const mob = MobFactory.create()

    composite.add(mob)

    const time = Math.random() * 500 + 1500

    mobSpawnerTimeoutToken = setTimeout(() => {
        mobSpawner(composite)
    }, time)
}

const stopGameBuilder =
    (composite: CompoundGraphic, startScreen: StartScreen) => () => {
        STATE.game.isRunning = false
        STATE.mobs.xSpeed = 0

        if (mobSpawnerTimeoutToken) {
            clearTimeout(mobSpawnerTimeoutToken)
        }

        composite.add(startScreen)
        window.alert(
            `Game Over: Você conseguiu spawnar ${STATE.mobs.count} mobs`
        )
    }

const startGameBuilder = (composite: CompoundGraphic, dino: Dino) => () => {
    composite.clear()

    STATE.mobs.xSpeed = STATE.mobs.initialXSpeed
    STATE.game.isRunning = true
    STATE.mobs.count = 0

    const ground = new Ground()

    composite.add(dino)
    composite.add(ground)

    mobSpawner(composite)
}

export const images = {
    dino: undefined,
    cactus: undefined,
    bat: undefined,
}

const sketch = (p5: P5) => {
    const compoundGraphic = new CompoundGraphic()

    const dino = Dino.getInstance(compoundGraphic)
    const startScreen = StartScreen.getInstance()

    const startGame = startGameBuilder(compoundGraphic, dino)
    const stopGame = stopGameBuilder(compoundGraphic, startScreen)

    p5.preload = () => {
        images.dino = p5.loadImage("../../assets/dino.png")
        images.cactus = p5.loadImage("../../assets/fael.png")
        images.bat = p5.loadImage("../../assets/pedro.png")
    }

    p5.setup = () => {
        // roda antes do primeiro frame
        const canvas = p5.createCanvas(STATE.canvas.width, STATE.canvas.height)
        canvas.parent("app")

        fixCoordinates(p5)

        compoundGraphic.add(startScreen)
    }

    p5.draw = () => {
        compoundGraphic.draw(p5)

        const dinoCollided = dino.hasCollided()

        if (dinoCollided && STATE.game.isRunning) {
            stopGame()
        }
    }

    p5.mousePressed = (e: MouseEvent) => {
        const button = e.button

        if (!STATE.game.isRunning) {
            startGame()
            return
        }

        if (button === 0) {
            dino.jump()
        } else if (button === 2) {
            dino.squat()
        }
    }

    p5.mouseReleased = () => {
        dino.squatReset()
    }
}

new P5(sketch)
