import { Graphic } from "../entities/Graphic"

type Point = {
    x: number
    y: number
}

export class CollisionChecker {
    public hasCollided(graphicA: Graphic, graphicB: Graphic) {
        const pointsA = this.getPoints(graphicA)

        const aIsInsideOfB = pointsA.some(point => {
            return this.pointIsInsideGraphic(point, graphicB)
        })

        if (aIsInsideOfB) return true

        const pointsB = this.getPoints(graphicB)
        const bIsInsideOfA = pointsB.some(point => {
            return this.pointIsInsideGraphic(point, graphicA)
        })

        if (bIsInsideOfA) return true
        return false
    }

    private getPoints(graphic: Graphic) {
        const topLeft: Point = { x: graphic.x, y: graphic.y + graphic.height }
        const topRight: Point = {
            x: graphic.x + graphic.width,
            y: graphic.y + graphic.height,
        }
        const bottomLeft: Point = { x: graphic.x, y: graphic.y }
        const bottomRight: Point = {
            x: graphic.x + graphic.width,
            y: graphic.y,
        }

        return [topLeft, topRight, bottomLeft, bottomRight]
    }

    private pointIsInsideGraphic(point: Point, graphic: Graphic) {
        const [topLeft, topRight, bottomLeft, bottomRight] =
            this.getPoints(graphic)

        const pointIsInsideX =
            point.x >= topLeft.x &&
            point.x >= bottomLeft.x &&
            point.x <= topRight.x &&
            point.x <= bottomRight.x

        const pointIsInsideY =
            point.y <= topLeft.y &&
            point.y <= topRight.y &&
            point.y >= bottomLeft.y &&
            point.y >= bottomRight.y

        return pointIsInsideY && pointIsInsideX
    }
}
