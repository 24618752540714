import P5 from "p5"
import { Graphic, GraphicType } from "./Graphic"
import { CompoundGraphic } from "./CompoundGraphic"
import { CollisionChecker } from "../helpers/CollisionChecker"
import { images } from "../app"
import { STATE } from "../constants"

export class Dino implements Graphic {
    private static dino: Dino

    public x: number = 100
    public y: number = STATE.ground.y
    public width: number = 160
    public height: number = 200
    readonly type = GraphicType.Dino
    ySpeed: number = 0
    yAcc: number = STATE.gravity
    readonly collisionChecker = new CollisionChecker()

    private constructor(public composite: CompoundGraphic) {}

    public static getInstance(composite: CompoundGraphic): Dino {
        if (!Dino.dino) {
            Dino.dino = new Dino(composite)
        }

        return Dino.dino
    }

    public draw(p5: P5) {
        p5.ellipseMode(p5.CORNER)

        if (this.y >= STATE.ground.y) {
            this.ySpeed += this.yAcc
            this.y += this.ySpeed

            if (this.y < STATE.ground.y) this.y = STATE.ground.y
        } else {
            this.ySpeed = 0
        }

        p5.stroke("#dddddd")
        p5.rect(this.x, this.y, this.width, this.height)

        const img = images.dino
        p5.imageMode(p5.CORNER)
        p5.image(img, this.x, this.y, this.width, this.height)
    }

    public jump() {
        if (this.y > STATE.ground.y) return

        this.ySpeed = STATE.dino.jumpYSpeed
    }

    public squat() {
        this.height = STATE.dino.squatHeight
    }

    public squatReset() {
        this.height = STATE.dino.standardHeight
    }

    public hasCollided(): boolean {
        for (const graphic of this.composite.getChildren()) {
            if (graphic.type === GraphicType.Mob) {
                const hasCollided = this.collisionChecker.hasCollided(
                    this,
                    graphic
                )
                if (hasCollided) return true
            }
        }
        return false
    }
}
