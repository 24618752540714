import P5 from "p5"

export enum GraphicType {
    Dino,
    Mob,
    Ground,
    Env,
    Background,
}

export interface Graphic {
    x: number
    y: number
    width: number
    height: number
    readonly type: GraphicType
    draw(p5: P5): void
}
