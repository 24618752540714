import { STATE } from "../constants"
import { Bat } from "../entities/Bat"
import { Cactus } from "../entities/Cactus"
import { Graphic } from "../entities/Graphic"

export interface Mob extends Graphic {}

export class MobFactory {
    public static create(): Mob {
        STATE.mobs.count += 1
        const randomNumber = Math.random()
        if (randomNumber < 0.7) {
            return new Cactus(230)
        }
        return new Bat()
    }
}
