import { fixCoordinates } from "../app"
import { STATE } from "../constants"
import { Graphic, GraphicType } from "./Graphic"
import P5 from "p5"

export class CompoundGraphic implements Graphic {
    public x: number = 0
    public y: number = 0
    public width: number = STATE.canvas.width
    public height: number = STATE.canvas.height
    public readonly type = GraphicType.Background

    private children: Graphic[] = []

    public draw(p5: P5) {
        fixCoordinates(p5)
        p5.background("light_blue")

        this.children.forEach(child => child.draw(p5))

        this.clearOutOfBounds()
    }

    public getChildren(): readonly Graphic[] {
        return this.children
    }

    public add(child: Graphic) {
        this.children.push(child)
    }

    public remove(index: number) {
        this.children.splice(index, 1)
    }

    public clear(type?: GraphicType) {
        if (type) {
            this.children = this.children.filter(child => child.type !== type)
            return
        }
        this.children = []
    }

    public clearOutOfBounds() {
        this.children.forEach((child, index) => {
            if (child.x < -child.width) {
                this.remove(index)
            }
        })
    }
}
