export const STATE = {
    gravity: -0.5,
    ground: {
        y: 100,
    },
    canvas: {
        width: window.innerWidth, //1900
        height: window.innerHeight * 0.9, //900
    },
    mobs: {
        xSpeed: -10,
        initialXSpeed: -10,
        count: 0,
    },
    game: {
        isRunning: false,
    },
    dino: {
        standardHeight: 200,
        squatHeight: 100,
        jumpYSpeed: 19,
    },
}
